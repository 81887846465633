import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService";

import unlimitedStressSweatIcon from "../../images/unlimited-stress-sweat.png";
import unlimitedHeatIcon from "../../images/unlimited-heat.png";
import unlimitedMovementIcon from "../../images/unlimited-movement.png";

import "./styles.scss";
import { Link } from "../Link";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

function setGradientCSSClass(colorObj: { value: string; title: string }) {
  const title = colorObj?.title.toLowerCase();
  return title ? `rx-gradient--${title}` : "rx-theme-unlimited";
}

const HeroV3 = props => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const { bgColor, heading, ctaLabel, ctaLink, copyTextAlignment, _rawCopyText, fontColor } = props;

  const { sanityProductLandingPageV2 } = useContext(LocalizedContext);

  const parentPath = ctaLink?.parentSection?.parentSection
    ? `${ctaLink?.parentSection?.parentSection.slug.current}/${ctaLink.parentSection?.slug.current}`
    : ctaLink?.parentSection?.slug.current;

  const getCtaURL = () => {
    if (ctaLink?.__typename === "SanityProductLinePageV2") {
      return `/${sanityProductLandingPageV2?.slug.current}/${ctaLink.slug?.current}/`;
    } else if (ctaLink?.__typename === "SanityArticle" && ctaLink.parentSection) {
      return `/${parentPath}/${ctaLink.slug?.current}/`;
    } else {
      return `/${ctaLink?.slug?.current}/`;
    }
  };

  const componentVariation = copyTextAlignment?.toLowerCase() || "top-center";

  const renderCTA = () => {
    return (
      <Link
        _id={ctaLink._id}
        to={getCtaURL()}
        className={"rx-c-herov3-cta"}
        {...(ctaLink.__typename === "SanityArticle" && { "data-analytics-event4": "" })}
      >
        {ctaLabel}
      </Link>
    );
  };

  return (
    <section
      data-testid="herov3"
      className={`rx-c-herov3 ${componentVariation}`}
      style={{
        ...(bgColor
          ? {
              backgroundColor: `${bgColor.value}`
            }
          : null)
      }}
    >
      <div className="rx-c-herov3-content">
        <Container fluid className="rx-c-herov3-body">
          <Row>
            <Col sm={12}>
              <div className="rx-c-herov3-copy">
                {heading && (
                  <h2 className="rx-c-herov3-title">
                    <span className={setGradientCSSClass(fontColor)}>{heading.split("-")[0]}</span>
                    <span>
                      {heading.split("-")[1]}
                      <sup>{heading.split("-")[2]}</sup>
                    </span>
                  </h2>
                )}
                {componentVariation === "top-right" ? (
                  <div className="rx-anim-vertical">
                    <div>{heading && heading.split("-")[3]}</div>
                    <div className="rx-slidingVertical">
                      <span>
                        <img src={unlimitedStressSweatIcon} width="46" height="55" /> {heading && heading.split("-")[4]}
                      </span>
                      <span>
                        <img src={unlimitedHeatIcon} width="61" height="55" /> {heading && heading.split("-")[5]}
                      </span>
                      <span>
                        <img src={unlimitedMovementIcon} width="61" height="55" /> {heading && heading.split("-")[6]}
                      </span>
                    </div>
                  </div>
                ) : null}
                {ctaLink && ctaLabel && renderCTA()}
              </div>
              {_rawCopyText ? (
                <div className="rx-c-herov3-tnc">
                  <BlockContent blocks={_rawCopyText} serializers={RichTextSerializers()} />
                </div>
              ) : null}
              <div className="rx-c-herov3-image">
                {props._rawHeroBgImage && (
                  <picture className="placeholder">
                    <source
                      media={"(min-width: 1200px)"}
                      srcSet={
                        urlBuilder
                          .image(props._rawHeroBgImage)
                          .ignoreImageParams()
                          .auto("format")
                          .quality(80)
                          .width(componentVariation === "top-center" ? 1400 : 1260)
                          .height(componentVariation === "top-center" ? 520 : 715)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <source
                      media={"(min-width: 992px)"}
                      srcSet={
                        urlBuilder
                          .image(props._rawHeroBgImage)
                          .ignoreImageParams()
                          .auto("format")
                          .quality(80)
                          .width(900)
                          .height(440)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <source
                      media={"(min-width: 375px)"}
                      srcSet={
                        urlBuilder
                          .image(props._rawHeroBgImage)
                          .ignoreImageParams()
                          .auto("format")
                          .quality(80)
                          .width(componentVariation === "top-center" ? 600 : 449)
                          .height(componentVariation === "top-center" ? 440 : 522)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <img
                      src={
                        urlBuilder
                          .image(props._rawHeroBgImage)
                          .ignoreImageParams()
                          .auto("format")
                          .quality(80)
                          .width(540)
                          .height(400)
                          .format("webp")
                          .url() as string
                      }
                      alt={props._rawHeroBgImage?.alt}
                    />
                  </picture>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default HeroV3;
